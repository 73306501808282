<template>
<v-lazy>

    <v-main>
        <div id="TheUrbanMac" class="macbook">
  <div class="screen">
    <div class="viewport viewportTheUrban" v-if="location == '/' || location == '/portfolio/the-urban-episode'"  ></div>
    <div class="viewport viewportMenasave"  v-if="location == '/portfolio/menasave'"></div>
    <div class="viewport viewportMaryud"  v-if="location == '/portfolio/maryud-fm'"></div>
    </div>
  <div class="base"></div>
  <div class="notch"></div>
  </div>
<!-- 
  <v-main >
        <div id="EthiMac" class="macbook">
  <div class="screen">
    <div class="viewportEthio"></div>
    </div>
  <div class="base"></div>
  <div class="notch"></div>
  </div>

    </v-main> -->

    </v-main>
</v-lazy>
</template>
<script>

export default {
    name:'MockUp',
    computed:{
        location(){
            return window.location.pathname
        }
    }
   }

</script>


<style lang="scss">
    @import '../sass/mockup.scss'
</style>