<template>
    <v-main>
        <Header></Header>
        <div class="section9">

    <v-container class="justify-center">
         <v-card-title class="justify-center"   style="margin:5em 0px 5em 0px; word-break: normal;"  primary-title>
             <h2>
           The Urban Episode 
             </h2>
         </v-card-title>
        </v-container>

             <v-container >
                <div class="space" >
                 <v-card  hover outlined raised color="rgba(139, 214, 255,0.2)">
                     <v-row>
                       <v-col>
                           <v-card-title class="justify-center" >    
                           <v-img height="3em" width="4em" contain :src="require('../assets/icons/location.svg')"></v-img>
                           </v-card-title>
                           <v-card-title style="word-break:normal" class="justify-center">
                           Khartoum, Sudan
                           </v-card-title>
                               
                       </v-col>
                       <v-col>
                      <v-card-title class="justify-center" >
                        
                      <v-img height="3em" width="4em" contain :src="require('../assets/icons/Category.svg')"></v-img>
                      </v-card-title>
                      <v-card-title style="word-break:normal" class="justify-center" >
                          UI/UX Design, Front-End  Development, Back-End Development
                      </v-card-title>
                     
                  </v-col>
                  <v-col>
                      <v-card-title class="justify-center" >
                         <v-img height="3em" width="4em" contain :src="require('../assets/icons/industry.svg')"></v-img>
                      </v-card-title>
                         <v-card-title style="word-break:normal" class="justify-center">
                            Research Studio & Architecture 
                         </v-card-title>
                  </v-col>
                </v-row>
            </v-card>
                 </div>
              </v-container>
          
           <v-container>
           <v-container>
        <v-row>
            <v-col cols="12" xl="3" lg="4" md="4" sm="12"   >
                 <v-container >
                <div class="space" >
                    <v-img  contain max-height="15em" max-width="15em" :src="require('../assets/portfolio/the urban episode logo.png')">
                    
                    </v-img>
                </div>
                  </v-container>
            </v-col>

                





            <v-col>
                <v-container>    
                <v-row>
                <h2>About The Urban Episode</h2> 
                <div class="space">
                    <v-col>
                    <p>
                        The Urban Episode is a research studio interested in city culture, history, planning and many other urban subjects. Some of their clients are the Goethe german institute and National museum of Sudan. 
                    </p>

                    </v-col>
                </div>
                </v-row>
                </v-container>
            </v-col>
        </v-row>
        </v-container>
         <v-container>
                    
        <v-row>
            <v-col >
                <h2>
                    What The Code Effect Did:
                </h2>
                <div class="space">
                    <v-container>
                        

                <p>
                 The Urban Episode provides many forms of research and studies on different levels of urban structure and tackling different aspects of the urban fabric. Along with the text and the articles that naturally come with research. They also produce visual and audio materials that explain their findings and method.
                 
                 <br> The Code Effect designed and developed a website  to establish online presence, and display the various forms of data produced by The Urban Episode.
                 With user experience and interface as a priority, the design allows the user to access the data in the form of maps, drawings, interviews, and many other mediums and formats.
                </p>
               </v-container>
          </div>
       </v-col>
    </v-row>
    
    <v-container >
            <v-row>
              <v-col lg="6" md="12" sm="12">
                      <MockUp/>
              </v-col>
            </v-row>
    </v-container>

          </v-container>
          </v-container>
          
        <Footer></Footer>
       </div>

    </v-main>
</template>
<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer'
import MockUp from '../components/MockUp'
// import IOsMockup from '../components/iOsMockup.vue'

export default {
    name: 'TheUrbanEpisode',
    components:{
        Header,
        MockUp,
        Footer,
        // IOsMockup 
    },
    
 

}
</script>
