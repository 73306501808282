<template>
<v-main>

<div class="space">
  <v-footer padless color="rgb(255, 255, 255, 0.02)">
<!-- <div class="round"> -->

    <v-card
      flat
      tile
      class=" text-center"
      color="rgb(255, 255, 255, 0.02)"
      
          >
      <v-card-text class="justify-center">
        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4"
          icon
          :href="icon.link"
        >
          <v-icon size="24px">
            {{ icon.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

        <!-- <div class="round"> -->
      <v-card-text class="pt-0 text-center">
        <!-- <p>
          We make solid & sound software that connects ideas with reality, Visit or Contact us through:
        </p> -->
        <v-card-text>
          
        <p >

       <v-icon small color="primary">mdi-office-building-outline</v-icon>
       <!-- <v-spacer></v-spacer> -->
       Sudan, Khartoum, Burri Allamab, Square 5 , Building No.234
        </p>
        
        </v-card-text>
        <v-card-text>
        
        <p>

       <!-- <v-divider></v-divider> -->
        <v-icon small color="primary">mdi_phone</v-icon>
        <!-- <v-spacer></v-spacer> -->
        (+249)901777157 <br>
        <v-icon small color="primary">mdi_phone</v-icon>

        (+249)908835582
        </p>
        
        </v-card-text>


        <v-card-text>
        <p >
         <v-icon small color="primary">mdi-email</v-icon>
         <!-- <v-spacer></v-spacer> -->
         contact@thecodeeffect.com
        </p>
        </v-card-text>
      </v-card-text>
        
        
       
      
        
      <v-divider></v-divider>

      <v-card-text >
      <p>   {{ new Date().getFullYear() }} —  <strong>The Code Effect</strong> </p>
      </v-card-text>
    </v-card>
  <!-- </div> -->
  </v-footer>
    </div>
  </v-main>
</template>
<script>
export default {
  name: 'Footer',
      data: () => ({
        icons: [{

          icon:'mdi-instagram',
          link:'https://www.instagram.com/thecodeeffect_'
        },{

          icon:'mdi-twitter',
          link:''
        },{

          icon:'mdi-linkedin',
          link:''
        }
      ],
    }),
}
</script>